<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'empty') {
    <empty-layout></empty-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Centered -->
@if (layout === 'centered') {
    <centered-layout></centered-layout>
}

<!-- Enterprise -->
@if (layout === 'enterprise') {
    <enterprise-layout></enterprise-layout>
}

<!-- Material -->
@if (layout === 'material') {
    <material-layout></material-layout>
}

<!-- Modern -->
@if (layout === 'modern') {
    <modern-layout></modern-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Classic -->
@if (layout === 'classic') {
    <classic-layout></classic-layout>
}

<!-- Classy -->
@if (layout === 'classy') {
    <classy-layout></classy-layout>
}

<!-- Compact -->
@if (layout === 'compact') {
    <compact-layout></compact-layout>
}

<!-- Dense -->
@if (layout === 'dense') {
    <dense-layout></dense-layout>
}

<!-- Futuristic -->
@if (layout === 'futuristic') {
    <futuristic-layout></futuristic-layout>
}

<!-- Thin -->
@if (layout === 'thin') {
    <thin-layout></thin-layout>
}

<!-- Environments information -->
<au-environments-information
    class="pointer-events-none fixed bottom-0 left-0 right-0 text-xs opacity-60"
></au-environments-information>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- <settings></settings> -->
<au-environments-information class="fixed bottom-0 left-0 text-xs opacity-60 right-0 pointer-events-none"></au-environments-information>